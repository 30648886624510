/* Default body styles for font normalization */
body {
    font-family: 'Space Mono', monospace;
  font-size: 16px; /* Default font size */
}


/* Styles for the glass-style class */
.glass-style {
    position: absolute;
    top: 60%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%); /* Vendor prefix for Safari */
    transform: translate(-50%, -50%);
    background: linear-gradient(135deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8) 100%);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 12.5rem; /* Changed from px to rem for better scaling */
    color: #333;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1000;
    padding: 1.25rem; /* Changed from px to rem for better scaling */
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-family: 'Space Mono', monospace;
}

/* Adjusted glass-style for mobile view */
.glass-style.mobile {
  top: 3.125rem; /* Converted from px to rem */
  width: 100%; /* Adjust width for mobile view */
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .glass-style.mobile {
    position: absolute; /* Fixed position to stick to the bottom */
    bottom: 30%; /* Align to the bottom of the screen */
    left: 10%; /* Center horizontally */
    /* Full width */
    top: auto; /* Override any top property */
    -webkit-transform: none; /* Vendor prefix for Safari */
    transform: none; /* Override any transform property */
  }

  .glass-style.mobile {
    width: 70%;
  }
}

/* Styles for button-style class */
.button-style {
  padding: 0.625rem 1.25rem; /* Converted from px to rem, adjusted padding for better button sizing */
  border: 1px solid #8de98d; /* Adjusted border style */
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.875rem; /* Converted from px to rem, increased font size */
  margin-top: 1.25rem; /* Converted from px to rem, added margin */
  background-color: #8de98d;
  color: #0a0808;
  width: 100%; /* Make buttons full width */
  font-family: 'Space Mono', monospace;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Added shadow for hover effect */
  transition: all 0.3s ease; /* Smooth transition for hover effect */
}

.button-style:hover {
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.5); /* Enhanced shadow on hover */
  -webkit-transform: translateY(-3px); /* Vendor prefix for Safari */
  transform: translateY(-3px); /* Slight upward shift on hover */
}

/* Styles for button-container class */
.button-container {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
}

/* Styles for location-info class */
.location-info {
  margin-bottom: 1.25rem; /* Converted from px to rem, increased bottom margin for more spacing */
  font-family: 'Space Mono', monospace;
}

/* Styles for earth-globe-container class */
.earth-globe-container {
  -webkit-tap-highlight-color: transparent;
  width: 100vw;
  height: 100vh;
  position: relative;
}

/* Styles for earth-globe-mount class */
.earth-globe-mount {
  width: 100vw;
  height: 100vh;
}

/* Styles for rubicon-title class */

.rubicon-contain {
  position: absolute;
  top: 3%;
  left: 50%;
  transform: translateX(-50%);
}
.rubicon-title {
  font-family: 'Space Mono', monospace;
  font-size: 28px;
  color: whitesmoke;
  font-weight: bold;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
}

/* Styles for rubicon-subtitle class */
.rubicon-subtitle {
  font-family: 'Space Mono', monospace;

  font-size: 10px;
  color: whitesmoke;
  font-weight: 500;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .rubicon-title {
    font-size: 18px; /* Smaller font size for mobile */
    top: 3%; /* Adjust position for mobile */
  }

  .rubicon-subtitle {
    font-size: 8px; /* Smaller font size for mobile */

  }
}

/* Styles for the footer */
.footer {
  position: absolute;
    font-size: 8px;
  bottom: 3%;
  width: 100%;
  text-align: center;
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0); /* Semi-transparent background */
  color: white;
  font-family: 'Space Mono', monospace;
}

/* Styles for social media icons */
.social-icons {
  display: inline-flex;
  justify-content: center;
  gap: 10px;
  margin-top: 0.5rem;
}

.social-icons svg {
  fill: whitesmoke; /* Icon color */
  width: 12px; /* Icon size */
  height: 12px;
  cursor: pointer;
}

.social-icons svg:hover {
  fill: #8de98d; /* Change color on hover */
}

.marqueecontainer {
  background-color: #0a0808;
  display: flex;
  width: 100%;
  height: 50vh;
  align-items: center;  


  
}

.marqueebox {
  width: 100%;


  
}

.marquee {
  display: flex;
  block-size: var(--marquee-item-height);
  padding-block: 50px;
  position: relative;

  overflow-x: hidden;
  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 20%,
    hsl(0 0% 0% / 1) 80%,
    hsl(0 0% 0% / 0)
  );
  align-items: center;

}

.marquee--8 {
  --marquee-item-width: min(10vw, 50px);
  --marquee-item-height: min(10vh, 50px);
  --marquee-duration: 30s;
  --marquee-items: 16;
}

/* Apply larger sizes for desktops */
@media (min-width: 768px) {
  .marquee--8 {
    --marquee-item-width: 100px;
    --marquee-item-height: 100px;
  }
}


.marquee__item {
  --marquee-item-offset: max(
    calc(var(--marquee-item-width) * var(--marquee-items)),
    calc(300% + var(--marquee-item-width))
  );
  --marquee-delay: calc(var(--marquee-duration) / var(--marquee-items) * (var(--marquee-items) - var(--marquee-item-index)) * -1);
  position: absolute;
  inset-inline-start: var(--marquee-item-offset);
  animation: go linear var(--marquee-duration) var(--marquee-delay, 0s) infinite;
  width: min(10vw, 100px); 
  height: min(10vw, 100px); 
  transform-origin: center center;
}



.marquee--8 .marquee__item:nth-of-type(1) {
  --marquee-item-index: 1;
}

.marquee--8 .marquee__item:nth-of-type(2) {
  --marquee-item-index: 2;
}

.marquee--8 .marquee__item:nth-of-type(3) {
  --marquee-item-index: 3;
}

.marquee--8 .marquee__item:nth-of-type(4) {
  --marquee-item-index: 4;
}

.marquee--8 .marquee__item:nth-of-type(5) {
  --marquee-item-index: 5;
}

.marquee--8 .marquee__item:nth-of-type(6) {
  --marquee-item-index: 6;
}

.marquee--8 .marquee__item:nth-of-type(7) {
  --marquee-item-index: 7;
}

.marquee--8 .marquee__item:nth-of-type(8) {
  --marquee-item-index: 8;
}

.marquee--8 .marquee__item:nth-of-type(9) {
  --marquee-item-index: 9;
}

.marquee--8 .marquee__item:nth-of-type(10) {
  --marquee-item-index: 10;
}

.marquee--8 .marquee__item:nth-of-type(11) {
  --marquee-item-index: 11;
}

.marquee--8 .marquee__item:nth-of-type(12) {
  --marquee-item-index: 12;
}

.marquee--8 .marquee__item:nth-of-type(13) {
  --marquee-item-index: 13;
}

.marquee--8 .marquee__item:nth-of-type(14) {
  --marquee-item-index: 14;
}
.marquee--8 .marquee__item:nth-of-type(15) {
  --marquee-item-index: 15;
}
.marquee--8 .marquee__item:nth-of-type(16) {
  --marquee-item-index: 16;
}


@keyframes go {
  to {  
    inset-inline-start: calc(var(--marquee-item-width) * -1);
  }
}

.partners-title {
  font-family: 'Space Mono', monospace;
  text-align: center;
  width: 100%;
  padding-block: 30px;
  font-size: 28px;
  color: whitesmoke;
  font-weight: bold;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
}

/* @keyframes recoil {
  0% {
    inset-inline-start: 0;
  }
  50% {
    inset-inline-start: calc(var(--marquee-item-width) * -0.06); 
  }
  100% {
    inset-inline-start: 0; 
  }
} */
@media (min-width: 769px) {
.marquee--8:hover{
  animation-play-state: paused;
  /* animation: recoil 0.4s ease-in-out; */
}
.marquee--8:hover .marquee__item{
  animation-play-state: paused;

}

.marquee__item {
  transition: transform 0.3s ease-in-out;
  transform-origin: center center;
  cursor: pointer;
}

.marquee__item:hover {
  transform: scale(1.5);
}
}
.marquee__image {
  width: min(10vw, 100px); 
  height: min(10vw, 100px); 
}



