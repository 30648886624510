

body {
  margin: 0;
  font-family:Rama Gothic Bold, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Rama Gothic Bold, sans-serif
}

